document.addEventListener('DOMContentLoaded', function () {
    const dropdownSubmenus = document.querySelectorAll('.dropdown-submenu');

    dropdownSubmenus.forEach((submenu) => {
        submenu.addEventListener('mouseenter', () => {
            const dropdownMenu = submenu.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                dropdownMenu.classList.add('show');
            }
        });

        submenu.addEventListener('mouseleave', () => {
            const dropdownMenu = submenu.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                dropdownMenu.classList.remove('show');
            }
        });
    });
});
