import Rails from "@rails/ujs"

global.$ = jQuery;
require("@rails/activestorage").start();
require("channels");

Rails.start()

import 'bootstrap/dist/js/bootstrap'
import './recaptcha'
import '../src/form'
import '../src/dropdown'
import 'bootstrap/dist/css/bootstrap'
import 'bootstrap/scss/bootstrap.scss'
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)